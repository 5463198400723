import { faCheck } from "@fortawesome/pro-light-svg-icons";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "next-i18next";
import React from "react";
import SquareIcon from "./SquareIcon";
import { HorizontalSpacer, VerticalSpacer } from "./utils/spacers";

const namespaces = ["marketing_module"];

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: "10%",
    marginRight: "10%",
    maxWidth: "80%",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: 0,
      maxWidth: "100%",
      minHeight: "100vh",
    },
    minHeight: "100vh",
    flex: 1,
    padding: "2rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  brand: {
    color: theme.palette.primary.main,
  },
  marketingTitle: {
    fontSize: "22px",
    fontWeight: 500,
    textAlign: "center",
    maxWidth: "80%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "90%",
    },
  },
  marketingImage: {
    width: "100%",
  },
  checkmarkIcons: {
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  checkmarkItem: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "24px",
    },
  },
  checkmarkIcon: {
    borderRadius: "50%",
    background: theme.palette.success.main,
    color: "white",
    padding: 5,
    width: 24,
    height: 24,
  },
  quoteBody: {
    textAlign: "center",
    fontWeight: 400,
    fontSize: "24px",
  },
  quoteImage: {
    borderRadius: "50%",
    width: "88px",
    marginRight: theme.spacing(3),
  },
  quoteSubtitle: {
    textAlign: "center",
    fontWeight: 400,
    fontSize: "16px",
  },
  brandMatrix: {
    width: "100%",
  },
}));

export enum MarketingModuleType {
  Image = "image",
  Quote = "quote",
}

interface IMarketingModuleProps {
  type?: MarketingModuleType;
}

export default function MarketingModule({
  type = MarketingModuleType.Image,
}: IMarketingModuleProps) {
  const classes = useStyles();
  const { t } = useTranslation(namespaces);

  function renderCheckmarkItem(label: string) {
    return (
      <div className={classes.checkmarkItem}>
        <SquareIcon
          className={classes.checkmarkIcon}
          icon={faCheck}
          size="2x"
        />
        <HorizontalSpacer size={3} />
        <Typography>{label}</Typography>
      </div>
    );
  }

  switch (type) {
    case MarketingModuleType.Image:
      return (
        <div className={classes.container}>
          <Typography
            className={classes.marketingTitle}
            variant="h3"
            component="h3"
            gutterBottom
          >
            <span className={classes.brand}>
              {t("marketing_module:image.titleStart")}
            </span>{" "}
            {t("marketing_module:image.titleEnd")}
          </Typography>

          <VerticalSpacer size={2} />

          <img
            src={"/static/images/landing-page-demo-event.png"}
            className={classes.marketingImage}
          />

          <VerticalSpacer size={4} />

          <div className={classes.checkmarkIcons}>
            {renderCheckmarkItem(
              t("marketing_module:image.checkmarkItems.meetings")
            )}
            {renderCheckmarkItem(
              t("marketing_module:image.checkmarkItems.voting")
            )}
            {renderCheckmarkItem(
              t("marketing_module:image.checkmarkItems.quorum")
            )}
            {renderCheckmarkItem(
              t("marketing_module:image.checkmarkItems.reporting")
            )}
          </div>
        </div>
      );
    case MarketingModuleType.Quote:
      return (
        <div className={classes.container}>
          <Typography
            className={classes.marketingTitle}
            variant="h3"
            component="h3"
            gutterBottom
          >
            {t("marketing_module:quote.titleStart")}{" "}
            <span className={classes.brand}>
              {t("marketing_module:quote.titleEnd")}
            </span>
          </Typography>

          <VerticalSpacer size={3} />

          <Typography
            variant="h3"
            component="h3"
            gutterBottom
            className={classes.quoteBody}
          >
            {t("marketing_module:quote.body")}
          </Typography>

          <VerticalSpacer size={4} />

          <Box display="flex">
            <img
              className={classes.quoteImage}
              src="/static/images/landing-page-quote-image.png"
            />
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Typography variant="h3" gutterBottom>
                {t("marketing_module:quote.sourceName")}
              </Typography>
              <Typography>{t("marketing_module:quote.sourceRole")}</Typography>
            </Box>
          </Box>

          <VerticalSpacer size={10} />

          <Typography
            variant="h5"
            component="h5"
            className={classes.quoteSubtitle}
          >
            {t("marketing_module:quote:subtitle")}
          </Typography>

          <VerticalSpacer size={4} />

          <img
            src={"/static/images/landing-page-brand-matrix.png"}
            className={classes.brandMatrix}
          />
        </div>
      );
    default:
      return null;
  }
}

MarketingModule.namespaces = [...namespaces];
